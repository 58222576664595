// src/apiService.js

const API_BASE_URL = 'https://statsdoomedbackend-966267534fbe.herokuapp.com/api'; // Updated backend API URL

export async function fetchData(endpoint) {
  try {
    const response = await fetch(`${API_BASE_URL}/${endpoint}`);
    if (!response.ok) {
      throw new Error(`Error fetching ${endpoint}: ${response.status} ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('API fetch error:', error);
    throw error;
  }
}

export async function fetchKnownOriginOwners(params = {}) {
  const queryString = new URLSearchParams(params).toString();
  return fetchData(`knownorigin/owners${queryString ? `?${queryString}` : ''}`);
}

export async function fetchKnownOriginPrices(params = {}) {
  const queryString = new URLSearchParams(params).toString();
  return fetchData(`knownorigin/prices${queryString ? `?${queryString}` : ''}`);
}

export async function fetchKnownOriginSales(params = {}) {
  const queryString = new URLSearchParams(params).toString();
  return fetchData(`knownorigin/sales${queryString ? `?${queryString}` : ''}`);
}
