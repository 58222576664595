import React, { useEffect, useState } from 'react';
import { fetchData } from '../apiService';
import ReactPaginate from 'react-paginate';
import './XCOPYHodlersComponent.css';
import { ArrowUpDown } from "lucide-react";

function XCOPYHodlersComponent() {
  // State for holders data
  const [holdersData, setHoldersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const recordsPerPage = 25;

  // Filter and search state
  const [collection, setCollection] = useState('all');
  const [searchAddress, setSearchAddress] = useState('');
  const [sortBy, setSortBy] = useState('memes_count');
  const [sortOrder, setSortOrder] = useState('desc');

  // Fetch data with all parameters
  useEffect(() => {
    const getHoldersData = async () => {
      setLoading(true);
      setError(null);
      try {
        const params = new URLSearchParams();
        params.append('page', currentPage);
        params.append('limit', recordsPerPage);
        params.append('collection', collection);
        params.append('search', searchAddress);
        params.append('sort_by', sortBy);
        params.append('sort_order', sortOrder);

        const data = await fetchData(`xcopyhodlers?${params.toString()}`);
        setHoldersData(data.data);
        setTotalPages(data.totalPages);
        setTotalRecords(data.totalRecords);
        setLoading(false);
      } catch (err) {
        setError(err.message || 'Unexpected Error');
        setLoading(false);
      }
    };

    // Add a small delay for search to prevent too many requests while typing
    const timeoutId = setTimeout(() => {
      getHoldersData();
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [currentPage, collection, searchAddress, sortBy, sortOrder]);

  // Handle page change
  const handlePageClick = (selectedItem) => {
    setCurrentPage(selectedItem.selected + 1);
  };

  // Handle collection filter change
  const handleCollectionChange = (e) => {
    setCollection(e.target.value);
    setCurrentPage(1);
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchAddress(e.target.value);
    setCurrentPage(1);
  };

  // Handle sorting
  const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortOrder('desc');
    }
    setCurrentPage(1);
  };

  // Render loading state
  if (loading) {
    return (
      <div className="min-h-screen bg-gray-950 text-white flex flex-col items-center justify-center p-4">
        <h2 className="text-3xl font-bold mb-4">XCOPY Hodlers</h2>
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16 mb-4"></div>
        <p className="text-gray-400">Loading...</p>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div className="min-h-screen bg-gray-950 text-white flex flex-col items-center justify-center p-4">
        <h2 className="text-3xl font-bold mb-4">XCOPY Hodlers</h2>
        <p className="text-red-400">Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-950 p-4">
      {/* Centered Header with two-tone coloring */}
      <div className="text-center mb-8">
        <h2 className="text-4xl font-bold">
          <span className="text-white">XCOPY</span>
          <span className="text-red-500"> Hodlers</span>
        </h2>
      </div>

      {/* Controls Row */}
      <div className="flex flex-col space-y-4 md:flex-row md:items-center md:justify-between md:space-y-0 md:gap-4 mb-6">
        {/* Collection Filter */}
        <div className="w-full md:w-auto">
          <label htmlFor="collection" className="block md:inline-block mr-2 text-gray-400 mb-1 md:mb-0">Filter by Collection:</label>
          <select
            id="collection"
            value={collection}
            onChange={handleCollectionChange}
            className="w-full md:w-auto bg-gray-800 text-gray-300 border border-gray-700 rounded px-3 py-1 focus:outline-none focus:ring-2 focus:ring-gray-600"
          >
            <option value="all">All Collections</option>
            <option value="memes">6529 Memes Exit Strategy</option>
            <option value="knownorigin">Known Origin</option>
            <option value="grifters">Grifters</option>
          </select>
        </div>

        {/* Search Bar */}
        <div className="w-full md:w-auto md:flex-1 md:max-w-md">
          <input
            type="text"
            placeholder="Search by address..."
            value={searchAddress}
            onChange={handleSearchChange}
            className="w-full bg-gray-800 text-gray-300 border border-gray-700 rounded px-3 py-1 focus:outline-none focus:ring-2 focus:ring-gray-600"
          />
        </div>

        {/* Total Records */}
        <div className="text-gray-400 text-center md:text-right">
          Total Unique Addresses: <span className="text-white">{totalRecords}</span>
        </div>
      </div>

      {/* Holders Table */}
      <div className="w-full overflow-x-auto">
        <table className="min-w-full bg-black border-collapse">
          <thead>
            <tr className="bg-gray-900">
              <th className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">
                Address
              </th>
              <th 
                className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort('memes_count')}
              >
                <div className="flex items-center gap-1">
                  6529 Exit Strategy
                  <ArrowUpDown className="h-4 w-4" />
                  {sortBy === 'memes_count' && (sortOrder === 'asc' ? '↑' : '↓')}
                </div>
              </th>
              <th 
                className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort('ko_count')}
              >
                <div className="flex items-center gap-1">
                  Known Origin
                  <ArrowUpDown className="h-4 w-4" />
                  {sortBy === 'ko_count' && (sortOrder === 'asc' ? '↑' : '↓')}
                </div>
              </th>
              <th 
                className="px-6 py-3 border-b border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort('grifters_count')}
              >
                <div className="flex items-center gap-1">
                  Grifters
                  <ArrowUpDown className="h-4 w-4" />
                  {sortBy === 'grifters_count' && (sortOrder === 'asc' ? '↑' : '↓')}
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-800">
            {holdersData.map((holder) => (
              <tr key={holder.address} className="hover:bg-gray-900 transition-colors duration-150">
                <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">
                  {holder.address}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">
                  {holder.memes_count}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">
                  {holder.ko_count}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300">
                  {holder.grifters_count}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="mt-4 flex justify-center">
        <ReactPaginate
          previousLabel={'← Previous'}
          nextLabel={'Next →'}
          breakLabel={'...'}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'flex space-x-2 text-gray-300'}
          pageClassName={'px-3 py-1 border border-gray-700 rounded hover:bg-gray-800 hover:text-white transition-colors duration-150'}
          previousClassName={'px-3 py-1 border border-gray-700 rounded hover:bg-gray-800 hover:text-white transition-colors duration-150'}
          nextClassName={'px-3 py-1 border border-gray-700 rounded hover:bg-gray-800 hover:text-white transition-colors duration-150'}
          breakClassName={'px-3 py-1 border border-gray-700 rounded'}
          activeClassName={'!bg-gray-800 !text-white'}
          disabledClassName={'opacity-50 cursor-not-allowed'}
          forcePage={currentPage - 1}
        />
      </div>
    </div>
  );
}

export default XCOPYHodlersComponent; 