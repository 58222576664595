import React, { useEffect, useState } from 'react';
import { fetchData } from '../apiService';
import ReactPaginate from 'react-paginate';
import { ChevronRight, ArrowUpDown } from 'lucide-react';
import './KnownOriginPricesComponent.css';

function KnownOriginPricesComponent() {
  // State for prices data
  const [pricesData, setPricesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const recordsPerPage = 25;

  // Additional state
  const [names, setNames] = useState([]);
  const [selectedName, setSelectedName] = useState('');

  // Add sort state
  const [sortBy, setSortBy] = useState('tokenid');
  const [sortOrder, setSortOrder] = useState('desc');

  // Format address to shortened version
  const formatAddress = (address) => {
    if (!address) return '-';
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  // Format timestamp to date
  const formatDate = (timestamp) => {
    if (!timestamp) return '-';
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    });
  };

  // Format minted date
  const formatMintedDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric'
    });
  };

  // Format ETH value
  const formatEth = (value) => {
    if (!value) return '-';
    return `${parseFloat(value).toFixed(3)} ETH`;
  };

  // Handle sorting
  const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortOrder('desc');
    }
    setCurrentPage(1);
  };

  // Fetch data
  useEffect(() => {
    const getPricesData = async () => {
      setLoading(true);
      setError(null);
      try {
        const params = new URLSearchParams({
          page: currentPage,
          limit: recordsPerPage,
          name_filter: selectedName,
          sort_by: sortBy,
          sort_order: sortOrder
        });

        console.log('Fetching with params:', {
          sort_by: sortBy,
          sort_order: sortOrder
        });

        const data = await fetchData(`knownorigin/prices?${params.toString()}`);
        console.log('Received data:', data);
        setPricesData(data.data);
        setTotalPages(data.totalPages);
        setTotalRecords(data.totalRecords);
        setNames(data.names || []);
        setLoading(false);
      } catch (err) {
        setError(err.message || 'Unexpected Error');
        setLoading(false);
      }
    };

    getPricesData();
  }, [currentPage, selectedName, sortBy, sortOrder]);

  // Handle page change
  const handlePageClick = (selectedItem) => {
    setCurrentPage(selectedItem.selected + 1);
  };

  // Handle name filter change
  const handleNameFilterChange = (e) => {
    setSelectedName(e.target.value);
    setCurrentPage(1);
  };

  // Render loading state
  if (loading) {
    return (
      <div className="min-h-screen bg-gray-950 text-white flex flex-col items-center justify-center p-4">
        <h2 className="text-4xl font-bold mb-4">
          <span className="text-white">Known Origin</span>
          <span className="text-red-500"> Prices</span>
        </h2>
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16 mb-4"></div>
        <p className="text-gray-400">Loading...</p>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div className="min-h-screen bg-gray-950 text-white flex flex-col items-center justify-center p-4">
        <h2 className="text-4xl font-bold mb-4">
          <span className="text-white">Known Origin</span>
          <span className="text-red-500"> Prices</span>
        </h2>
        <p className="text-red-400">Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-950 p-4">
      {/* Header */}
      <div className="text-center mb-8">
        <h2 className="text-4xl font-bold">
          <span className="text-white">Known Origin</span>
          <span className="text-red-500"> Prices</span>
        </h2>
      </div>

      {/* Controls */}
      <div className="flex flex-col space-y-4 md:flex-row md:items-center md:justify-between md:space-y-0 md:gap-4 mb-6">
        {/* Name Filter */}
        <div className="w-full md:w-auto">
          <select
            value={selectedName}
            onChange={handleNameFilterChange}
            className="w-full md:w-auto bg-gray-800 text-gray-300 border border-gray-700 rounded px-3 py-1 focus:outline-none focus:ring-2 focus:ring-gray-600"
          >
            <option value="">All Names</option>
            {names.map(name => (
              <option key={name} value={name}>{name}</option>
            ))}
          </select>
        </div>

        {/* Total Records */}
        <div className="text-gray-400">
          Total Items: <span className="text-white">{totalRecords}</span>
        </div>
      </div>

      {/* Table */}
      <div className="w-full overflow-x-auto desktop-scroll">
        <table className="min-w-full bg-black border-collapse">
          <thead>
            <tr className="bg-gray-900">
              <th 
                className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort('tokenid')}
              >
                <div className="flex items-center gap-1">
                  Token ID
                  <ArrowUpDown className="h-4 w-4" />
                  {sortBy === 'tokenid' && (sortOrder === 'asc' ? '↑' : '↓')}
                </div>
              </th>
              <th className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">Art</th>
              <th className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">Name</th>
              <th 
                className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort('minted_date')}
              >
                <div className="flex items-center gap-1">
                  Minted
                  <ArrowUpDown className="h-4 w-4" />
                  {sortBy === 'minted_date' && (sortOrder === 'asc' ? '↑' : '↓')}
                </div>
              </th>
              <th 
                className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort('lastsale_decimal')}
              >
                <div className="flex items-center gap-1">
                  Last Sale
                  <ArrowUpDown className="h-4 w-4" />
                  {sortBy === 'lastsale_decimal' && (sortOrder === 'asc' ? '↑' : '↓')}
                </div>
              </th>
              <th 
                className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort('lastsale_timestamp')}
              >
                <div className="flex items-center gap-1">
                  Last Sale Date
                  <ArrowUpDown className="h-4 w-4" />
                  {sortBy === 'lastsale_timestamp' && (sortOrder === 'asc' ? '↑' : '↓')}
                </div>
              </th>
              <th className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">Owner</th>
              <th 
                className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort('token_floor_decimal')}
              >
                <div className="flex items-center gap-1">
                  Token Floor
                  <ArrowUpDown className="h-4 w-4" />
                  {sortBy === 'token_floor_decimal' && (sortOrder === 'asc' ? '↑' : '↓')}
                </div>
              </th>
              <th 
                className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort('topbid_decimal')}
              >
                <div className="flex items-center gap-1">
                  Top Bid
                  <ArrowUpDown className="h-4 w-4" />
                  {sortBy === 'topbid_decimal' && (sortOrder === 'asc' ? '↑' : '↓')}
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-800">
            {pricesData.map((item) => (
              <tr key={item.tokenid} className="hover:bg-gray-900 transition-colors duration-150">
                <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">{item.tokenid}</td>
                <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">
                  {item.image_small ? (
                    <img 
                      src={item.image_small} 
                      alt={item.name} 
                      className="w-16 h-16 object-cover rounded"
                    />
                  ) : '-'}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">
                  <a 
                    href={`https://opensea.io/assets/ethereum/${item.contract}/${item.tokenid}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-300 hover:text-red-500 transition-colors duration-150 flex items-center gap-1"
                  >
                    {item.name || '-'}
                    <ChevronRight className="h-4 w-4 text-red-500" />
                  </a>
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">
                  {formatMintedDate(item.minted_date)}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">{formatEth(item.lastsale_decimal)}</td>
                <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">
                  {formatDate(item.lastsale_timestamp)}
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">
                  <a 
                    href={`https://opensea.io/${item.owner}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-300 hover:text-red-500 transition-colors duration-150 flex items-center gap-1"
                  >
                    {formatAddress(item.owner)}
                    <ChevronRight className="h-4 w-4 text-red-500" />
                  </a>
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">
                  <div className="flex items-center gap-2">
                    {formatEth(item.token_floor_decimal)}
                    {item.token_floor_marketplace_icon && (
                      <a href={item.token_floor_marketplace_url} target="_blank" rel="noopener noreferrer">
                        <img src={item.token_floor_marketplace_icon} alt="marketplace" className="w-4 h-4" />
                      </a>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">
                  <div className="flex items-center gap-2">
                    {formatEth(item.topbid_decimal)}
                    {item.topbid_marketplace_icon && (
                      <a href={item.topbid_marketplace_url} target="_blank" rel="noopener noreferrer">
                        <img src={item.topbid_marketplace_icon} alt="marketplace" className="w-4 h-4" />
                      </a>
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="mt-4 flex justify-center">
        <ReactPaginate
          previousLabel={'← Previous'}
          nextLabel={'Next →'}
          breakLabel={'...'}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'flex space-x-2 text-gray-300'}
          pageClassName={'px-3 py-1 border border-gray-700 rounded hover:bg-gray-800 hover:text-white transition-colors duration-150'}
          previousClassName={'px-3 py-1 border border-gray-700 rounded hover:bg-gray-800 hover:text-white transition-colors duration-150'}
          nextClassName={'px-3 py-1 border border-gray-700 rounded hover:bg-gray-800 hover:text-white transition-colors duration-150'}
          breakClassName={'px-3 py-1 border border-gray-700 rounded'}
          activeClassName={'!bg-gray-800 !text-white'}
          disabledClassName={'opacity-50 cursor-not-allowed'}
          forcePage={currentPage - 1}
        />
      </div>
    </div>
  );
}

export default KnownOriginPricesComponent; 