import React, { useEffect, useState } from 'react';
import { fetchKnownOriginOwners } from '../apiService';
import ReactPaginate from 'react-paginate';
import { ArrowUpDown, X, ChevronRight } from "lucide-react";
import './KnownOriginOwnersComponent.css';

function KnownOriginOwnersComponent() {
  // State for owners data
  const [ownersData, setOwnersData] = useState([]);
  const [columns, setColumns] = useState(['owner']);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const recordsPerPage = 25;

  // Search, filter and sort state
  const [searchAddress, setSearchAddress] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [sortBy, setSortBy] = useState('owner');
  const [sortOrder, setSortOrder] = useState('desc');

  // Calculate total tokens for an owner
  const calculateTotalTokens = (owner) => {
    return Object.entries(owner)
      .filter(([key]) => key !== 'owner')
      .reduce((sum, [_, count]) => sum + parseInt(count), 0);
  };

  // Fetch data with all parameters
  useEffect(() => {
    const getOwnersData = async () => {
      setLoading(true);
      setError(null);
      try {
        const params = {
          page: currentPage,
          limit: recordsPerPage,
          search: searchAddress,
          sort_by: sortBy,
          sort_order: sortOrder,
          name_filter: selectedName
        };

        const data = await fetchKnownOriginOwners(params);
        setOwnersData(data.data);
        setColumns(data.columns);
        setTotalPages(data.totalPages);
        setTotalRecords(data.totalRecords);
        setLoading(false);
      } catch (err) {
        setError(err.message || 'Unexpected Error');
        setLoading(false);
      }
    };

    // Add a small delay for search to prevent too many requests while typing
    const timeoutId = setTimeout(() => {
      getOwnersData();
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [currentPage, searchAddress, sortBy, sortOrder, selectedName]);

  // Handle page change
  const handlePageClick = (selectedItem) => {
    setCurrentPage(selectedItem.selected + 1);
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchAddress(e.target.value);
    setCurrentPage(1);
  };

  // Handle clear search
  const handleClearSearch = () => {
    setSearchAddress('');
    setCurrentPage(1);
  };

  // Handle name filter change
  const handleNameFilterChange = (e) => {
    setSelectedName(e.target.value);
    setCurrentPage(1);
  };

  // Handle sorting
  const handleSort = (field) => {
    if (sortBy === field) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortOrder('desc');
    }
    setCurrentPage(1);
  };

  // Format address to shortened version
  const formatAddress = (address) => {
    return `${address.slice(0, 6)}...${address.slice(-4)}`;
  };

  // Render loading state
  if (loading) {
    return (
      <div className="min-h-screen bg-gray-950 text-white flex flex-col items-center justify-center p-4">
        <h2 className="text-4xl font-bold mb-4">
          <span className="text-white">Known Origin</span>
          <span className="text-red-500"> Hodlers</span>
        </h2>
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16 mb-4"></div>
        <p className="text-gray-400">Loading...</p>
      </div>
    );
  }

  // Render error state
  if (error) {
    return (
      <div className="min-h-screen bg-gray-950 text-white flex flex-col items-center justify-center p-4">
        <h2 className="text-4xl font-bold mb-4">
          <span className="text-white">Known Origin</span>
          <span className="text-red-500"> Hodlers</span>
        </h2>
        <p className="text-red-400">Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-950 p-4">
      {/* Header */}
      <div className="text-center mb-8">
        <h2 className="text-4xl font-bold">
          <span className="text-white">Known Origin</span>
          <span className="text-red-500"> Hodlers</span>
        </h2>
      </div>

      {/* Controls */}
      <div className="flex flex-col space-y-4 md:flex-row md:items-center md:justify-between md:space-y-0 md:gap-4 mb-6">
        {/* Search Bar */}
        <div className="w-full md:w-auto md:flex-1 md:max-w-md relative">
          <input
            type="text"
            placeholder="Search by address..."
            value={searchAddress}
            onChange={handleSearchChange}
            className="w-full bg-gray-800 text-gray-300 border border-gray-700 rounded px-3 py-1 pr-8 focus:outline-none focus:ring-2 focus:ring-gray-600"
          />
          {searchAddress && (
            <button
              onClick={handleClearSearch}
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-white"
            >
              <X size={16} />
            </button>
          )}
        </div>

        {/* Name Filter */}
        <div className="w-full md:w-auto">
          <select
            value={selectedName}
            onChange={handleNameFilterChange}
            className="w-full md:w-auto bg-gray-800 text-gray-300 border border-gray-700 rounded px-3 py-1 focus:outline-none focus:ring-2 focus:ring-gray-600"
          >
            <option value="">All Names</option>
            {columns.slice(1).map(name => (
              <option key={name} value={name}>{name}</option>
            ))}
          </select>
        </div>

        {/* Total Records */}
        <div className="text-gray-400 text-center md:text-right">
          Total Unique Addresses: <span className="text-white">{totalRecords}</span>
        </div>
      </div>

      {/* Table */}
      <div className="w-full overflow-x-auto desktop-scroll">
        <table className="min-w-full bg-black border-collapse">
          <thead>
            <tr className="bg-gray-900">
              <th
                className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider cursor-pointer"
                onClick={() => handleSort('owner')}
              >
                <div className="flex items-center gap-1">
                  Address
                  <ArrowUpDown className="h-4 w-4" />
                  {sortBy === 'owner' && (sortOrder === 'asc' ? '↑' : '↓')}
                </div>
              </th>
              <th className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">
                Total Tokens
              </th>
              {columns.slice(1).map((column) => (
                <th
                  key={column}
                  className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider cursor-pointer"
                  onClick={() => handleSort(column)}
                >
                  <div className="flex items-center gap-1">
                    {column}
                    <ArrowUpDown className="h-4 w-4" />
                    {sortBy === column && (sortOrder === 'asc' ? '↑' : '↓')}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-800">
            {ownersData.map((owner) => (
              <tr key={owner.owner} className="hover:bg-gray-900 transition-colors duration-150">
                <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">
                  <a 
                    href={`https://opensea.io/${owner.owner}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-gray-300 hover:text-red-500 transition-colors duration-150 flex items-center gap-1"
                  >
                    {formatAddress(owner.owner)}
                    <ChevronRight className="h-4 w-4 text-red-500" />
                  </a>
                </td>
                <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">
                  {calculateTotalTokens(owner)}
                </td>
                {columns.slice(1).map((column) => (
                  <td key={`${owner.owner}-${column}`} className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">
                    {owner[column]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <div className="mt-4 flex justify-center">
        <ReactPaginate
          previousLabel={'← Previous'}
          nextLabel={'Next →'}
          breakLabel={'...'}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'flex space-x-2 text-gray-300'}
          pageClassName={'px-3 py-1 border border-gray-700 rounded hover:bg-gray-800 hover:text-white transition-colors duration-150'}
          previousClassName={'px-3 py-1 border border-gray-700 rounded hover:bg-gray-800 hover:text-white transition-colors duration-150'}
          nextClassName={'px-3 py-1 border border-gray-700 rounded hover:bg-gray-800 hover:text-white transition-colors duration-150'}
          breakClassName={'px-3 py-1 border border-gray-700 rounded'}
          activeClassName={'!bg-gray-800 !text-white'}
          disabledClassName={'opacity-50 cursor-not-allowed'}
          forcePage={currentPage - 1}
        />
      </div>
    </div>
  );
}

export default KnownOriginOwnersComponent; 