// src/Components/Navbar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar bg-gray-950 text-white">
      {/* Mobile Navigation */}
      <div className="md:hidden relative">
        {/* Hamburger Button */}
        <button 
          onClick={toggleMenu} 
          className="p-4 focus:outline-none"
          aria-label="Toggle menu"
        >
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}
            />
          </svg>
        </button>

        {/* Mobile Menu */}
        <div className={`
          absolute 
          left-0 
          top-full 
          w-64 
          bg-gray-950 
          border-r 
          border-gray-800
          shadow-lg 
          transition-transform 
          duration-200 
          ease-in-out
          transform
          ${isOpen ? 'translate-x-0' : '-translate-x-full'}
          z-50
        `}>
          <div className="py-4 space-y-4">
            <Link 
              to="/" 
              className="block px-6 py-2 hover:bg-gray-900 transition-colors duration-200"
              onClick={() => setIsOpen(false)}
            >
              Home
            </Link>
            <Link 
              to="/xcopy-hodlers" 
              className="block px-6 py-2 hover:bg-gray-900 transition-colors duration-200"
              onClick={() => setIsOpen(false)}
            >
              XCOPY Hodlers
            </Link>
            <Link 
              to="/exit-strategy" 
              className="block px-6 py-2 hover:bg-gray-900 transition-colors duration-200"
              onClick={() => setIsOpen(false)}
            >
              6529 Exit Strategy
            </Link>
            {/* Known Origin Section */}
            <div className="border-t border-gray-800 pt-4">
              <div className="px-6 py-2 text-gray-400 text-sm uppercase">Known Origin</div>
              <Link 
                to="/known-origin/owners" 
                className="block px-8 py-2 hover:bg-gray-900 transition-colors duration-200"
                onClick={() => setIsOpen(false)}
              >
                Owners
              </Link>
              <Link 
                to="/known-origin/prices" 
                className="block px-8 py-2 hover:bg-gray-900 transition-colors duration-200"
                onClick={() => setIsOpen(false)}
              >
                Prices
              </Link>
              <Link 
                to="/known-origin/sales" 
                className="block px-8 py-2 hover:bg-gray-900 transition-colors duration-200"
                onClick={() => setIsOpen(false)}
              >
                Sales
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Desktop Navigation - Centered */}
      <div className="hidden md:flex justify-center items-center p-4">
        <div className="flex space-x-12">
          <Link to="/" className="text-xl hover:text-gray-300 transition-colors duration-200">
            Home
          </Link>
          <Link to="/xcopy-hodlers" className="text-xl hover:text-gray-300 transition-colors duration-200">
            XCOPY Hodlers
          </Link>
          <Link to="/exit-strategy" className="text-xl hover:text-gray-300 transition-colors duration-200">
            6529 Exit Strategy
          </Link>
          {/* Known Origin Dropdown */}
          <div className="relative group">
            <span className="text-xl hover:text-gray-300 transition-colors duration-200 cursor-pointer">
              Known Origin
            </span>
            <div className="absolute left-0 mt-2 w-48 bg-gray-950 border border-gray-800 rounded-lg shadow-lg opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-200">
              <Link to="/known-origin/owners" className="block px-4 py-2 hover:bg-gray-900 transition-colors duration-200">
                Owners
              </Link>
              <Link to="/known-origin/prices" className="block px-4 py-2 hover:bg-gray-900 transition-colors duration-200">
                Prices
              </Link>
              <Link to="/known-origin/sales" className="block px-4 py-2 hover:bg-gray-900 transition-colors duration-200">
                Sales
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Overlay for mobile menu */}
      {isOpen && (
        <div 
          className="md:hidden fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsOpen(false)}
        ></div>
      )}
    </nav>
  );
}

export default Navbar;
