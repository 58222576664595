// src/Components/MemesSalesComponent.js

import React, { useEffect, useState } from 'react';
import { fetchData } from '../apiService';
import { ArrowUpDown } from "lucide-react";
import ReactPaginate from 'react-paginate';
import etherscanIcon from '../assets/etherscan.png';
import openseaIcon from '../assets/openseaIcon.png';
import seizeIcon from '../assets/seize.png';
import exitstrategyGif from '../assets/exitstrategy.gif';

function MemesSalesComponent() {
  // State for memessales data
  const [salesData, setSalesData] = useState([]);
  const [loadingSales, setLoadingSales] = useState(true);
  const [errorSales, setErrorSales] = useState(null);

  // State for aggregated data
  const [aggregatedData, setAggregatedData] = useState({
    additionalAttributes: {},
    calculations: {},
  });
  const [loadingAggregated, setLoadingAggregated] = useState(true);
  const [errorAggregated, setErrorAggregated] = useState(null);

  // State for memesowners data with pagination
  const [ownersData, setOwnersData] = useState([]);
  const [ownersPagination, setOwnersPagination] = useState({
    page: 1,
    limit: 25,
    totalPages: 1,
    totalRecords: 0,
  });
  const [loadingOwners, setLoadingOwners] = useState(true);
  const [errorOwners, setErrorOwners] = useState(null);

  // Pagination States for memessales
  const [currentPageSales, setCurrentPageSales] = useState(1);
  const [totalPagesSales, setTotalPagesSales] = useState(1);
  const [totalRecordsSales, setTotalRecordsSales] = useState(0);
  const recordsPerPageSales = 25;

  // Pagination States for memesowners
  const [currentPageOwners, setCurrentPageOwners] = useState(1);
  const recordsPerPageOwners = 25;

  // Filter and Sorting States for memessales
  const [saleType, setSaleType] = useState('all'); // 'all', 'ask', 'bid'
  const [sortBy, setSortBy] = useState('createdat'); // 'createdat', 'decimal', 'usd'
  const [sortOrder, setSortOrder] = useState('desc'); // Default to 'desc' for newest first

  // Mobile-specific state for collapsing the Key Metrics summary tiles
  const [showMetrics, setShowMetrics] = useState(false);

  /**
   * Fetch memessales data based on currentPageSales, saleType, sortBy, and sortOrder
   */
  useEffect(() => {
    const getSalesData = async () => {
      setLoadingSales(true);
      setErrorSales(null);
      try {
        const params = new URLSearchParams();
        params.append('page', currentPageSales);
        params.append('limit', recordsPerPageSales);
        if (saleType !== 'all') {
          params.append('orderside', saleType);
        }
        if (sortBy) {
          params.append('sort_by', sortBy);
          params.append('sort_order', sortOrder);
        }

        const data = await fetchData(`memessales?${params.toString()}`);
        setSalesData(data.data);
        setTotalPagesSales(data.totalPages);
        setTotalRecordsSales(data.totalRecords || 0);
        setLoadingSales(false);
      } catch (err) {
        setErrorSales(err.message || 'Unexpected Error');
        setLoadingSales(false);
      }
    };

    getSalesData();
  }, [currentPageSales, saleType, sortBy, sortOrder]);

  /**
   * Fetch aggregated data from /api/memessalesaggregated
   */
  useEffect(() => {
    const getAggregatedData = async () => {
      setLoadingAggregated(true);
      setErrorAggregated(null);
      try {
        const data = await fetchData('memessalesaggregated');
        setAggregatedData({
          additionalAttributes: data.additionalAttributes || {},
          calculations: data.calculations || {},
        });
        setLoadingAggregated(false);
      } catch (err) {
        setErrorAggregated(err.message || 'Unexpected Error');
        setLoadingAggregated(false);
      }
    };

    getAggregatedData();
  }, []);

  /**
   * Fetch memesowners data with pagination
   */
  useEffect(() => {
    const getOwnersData = async () => {
      setLoadingOwners(true);
      setErrorOwners(null);
      try {
        const params = new URLSearchParams();
        params.append('page', currentPageOwners);
        params.append('limit', recordsPerPageOwners);

        const data = await fetchData(`memesowners?${params.toString()}`);
        setOwnersData(data.data);
        setOwnersPagination({
          page: data.page,
          limit: data.limit,
          totalPages: data.totalPages,
          totalRecords: data.totalRecords,
        });
        setLoadingOwners(false);
      } catch (err) {
        setErrorOwners(err.message || 'Unexpected Error');
        setLoadingOwners(false);
      }
    };

    getOwnersData();
  }, [currentPageOwners, recordsPerPageOwners]);

  // Function to format date and time
  const formatDate = (isoString) => {
    const options = { year: 'numeric', month: 'short', day: '2-digit' };
    const date = new Date(isoString);
    const formattedDate = date.toLocaleDateString('en-US', options);
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: 'UTC',
    });
    return (
      <div>
        <div>{formattedDate}</div>
        <div className="text-gray-400 text-sm">{formattedTime} UTC</div>
      </div>
    );
  };

  // Function to handle sorting
  const handleSort = (field) => {
    if (sortBy === field) {
      // Toggle sort order
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortOrder('asc');
    }
    setCurrentPageSales(1); // Reset to first page on sort change
  };

  // Handle page change using ReactPaginate for memessales
  const handlePageClickSales = (selectedItem) => {
    setCurrentPageSales(selectedItem.selected + 1);
  };

  // Handle page change using ReactPaginate for memesowners
  const handlePageClickOwners = (selectedItem) => {
    setCurrentPageOwners(selectedItem.selected + 1);
  };

  // Render loading state for memessales, aggregated data, and memesowners
  if (loadingSales || loadingAggregated || loadingOwners) {
    return (
      <div className="min-h-screen bg-gray-950 text-white flex flex-col items-center justify-center p-4">
        <h2 className="text-3xl font-bold mb-4">Sales</h2>
        <div className="loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16 mb-4"></div>
        <p className="text-gray-400">Loading...</p>
      </div>
    );
  }

  // Render error state for memessales, aggregated data, or memesowners
  if (errorSales || errorAggregated || errorOwners) {
    return (
      <div className="min-h-screen bg-gray-950 text-white flex flex-col items-center justify-center p-4">
        <h2 className="text-3xl font-bold mb-4">Sales</h2>
        <p className="text-red-400">
          Error: {errorSales || errorAggregated || errorOwners}
        </p>
      </div>
    );
  }

  // Define the summary tiles once so they can be reused for desktop and mobile views
  const summaryTiles = (
    <>
      {/* Owners Count */}
      <div className="rounded-lg border border-red-900/20 bg-black p-6">
        <h3 className="text-lg font-semibold text-red-400">Owners Count</h3>
        <p className="mt-2 text-2xl font-bold text-white">{ownersPagination.totalRecords || 'N/A'}</p>
      </div>

      {/* Market Cap */}
      <div className="rounded-lg border border-red-900/20 bg-black p-6">
        <h3 className="text-lg font-semibold text-red-400">Market Cap</h3>
        <p className="mt-2 text-2xl font-bold text-white">
          {aggregatedData.additionalAttributes.market_cap
            ? `$${parseFloat(aggregatedData.additionalAttributes.market_cap).toLocaleString()}`
            : 'N/A'}
        </p>
      </div>

      {/* All Time Revenue */}
      <div className="rounded-lg border border-red-900/20 bg-black p-6">
        <h3 className="text-lg font-semibold text-red-400">All Time Revenue</h3>
        <p className="mt-2 text-2xl font-bold text-white">
          {aggregatedData.additionalAttributes.total_volume
            ? `$${parseFloat(aggregatedData.additionalAttributes.total_volume).toLocaleString()}`
            : 'N/A'}
        </p>
      </div>

      {/* Floor Price */}
      <div className="rounded-lg border border-red-900/20 bg-black p-6">
        <h3 className="text-lg font-semibold text-red-400">Floor Price</h3>
        <p className="mt-2 text-2xl font-bold text-white">
          {aggregatedData.additionalAttributes.floor_price
            ? `${parseFloat(aggregatedData.additionalAttributes.floor_price).toFixed(2)} ETH`
            : 'N/A'}
        </p>
      </div>

      {/* Top Bid */}
      <div className="rounded-lg border border-red-900/20 bg-black p-6">
        <h3 className="text-lg font-semibold text-red-400">Top Bid</h3>
        <p className="mt-2 text-2xl font-bold text-white">
          {aggregatedData.additionalAttributes.highest_offer
            ? `${parseFloat(aggregatedData.additionalAttributes.highest_offer).toFixed(2)} ETH`
            : 'N/A'}
        </p>
      </div>

      {/* Last Sale */}
      <div className="rounded-lg border border-red-900/20 bg-black p-6">
        <h3 className="text-lg font-semibold text-red-400">Last Sale</h3>
        <p className="mt-2 text-xl font-bold text-white">
          {aggregatedData.calculations.last_sale_price && aggregatedData.calculations.last_sale_date
            ? `${aggregatedData.calculations.last_sale_price.toFixed(2)} ETH on ${new Date(aggregatedData.calculations.last_sale_date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              })}`
            : 'N/A'}
        </p>
      </div>

      {/* Highest Sale Price */}
      <div className="rounded-lg border border-red-900/20 bg-black p-6">
        <h3 className="text-lg font-semibold text-red-400">Highest Sale Price</h3>
        <p className="mt-2 text-xl font-bold text-white">
          {aggregatedData.calculations.highest_sale_price && aggregatedData.calculations.highest_sale_price_date
            ? `${aggregatedData.calculations.highest_sale_price.toFixed(2)} ETH on ${new Date(aggregatedData.calculations.highest_sale_price_date).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: '2-digit',
              })}`
            : 'N/A'}
        </p>
      </div>

      {/* Average Sale Price */}
      <div className="rounded-lg border border-red-900/20 bg-black p-6">
        <h3 className="text-lg font-semibold text-red-400">Average Sale Price</h3>
        <p className="mt-2 text-2xl font-bold text-white">
          {aggregatedData.calculations.average_sale_price
            ? `${aggregatedData.calculations.average_sale_price.toFixed(2)} ETH`
            : 'N/A'}
        </p>
      </div>

      {/* Last 7 Days Revenue */}
      <div className="rounded-lg border border-red-900/20 bg-black p-6">
        <h3 className="text-lg font-semibold text-red-400">Last 7 Days Revenue</h3>
        <p className="mt-2 text-2xl font-bold text-white">
          {aggregatedData.additionalAttributes.total_volume_last_7_days
            ? `$${parseFloat(aggregatedData.additionalAttributes.total_volume_last_7_days).toLocaleString()}`
            : 'N/A'}
        </p>
      </div>
    </>
  );

  return (
    <div className="min-h-screen bg-gray-950 p-4">
      {/* Header Section */}
      <div className="grid gap-8 lg:grid-cols-2 mb-8">
        {/* Left Column: Image */}
        <div className="flex w-full items-center justify-center rounded-lg border border-red-900/20 bg-black p-4">
          <a href={exitstrategyGif} target="_blank" rel="noopener noreferrer">
            <img
              src={exitstrategyGif}
              alt="Exit Strategy"
              className="w-full h-auto object-contain rounded cursor-pointer"
            />
          </a>
        </div>

        {/* Right Column: Metrics Grid and Description */}
        <div className="space-y-6">
          {/* Metrics Grid - Desktop Only */}
          <div className="hidden lg:grid gap-4 grid-cols-2 content-start">
            {summaryTiles}
          </div>

          {/* NFT Details */}
          <div className="rounded-lg border border-red-900/20 bg-black p-6">
            <div>
              <h1 className="text-4xl font-bold text-white">Exit Strategy</h1>
              <p className="mt-2 text-xl text-red-400">6529 Memes Card 47</p>
            </div>

            <p className="text-gray-300 mt-4">
              Exit Strategy was the final card in the opening season 1 of the 6529 Memes collection. 
              There are nearly 300 cards at the time of writing and Exit Strategy is still one of the 
              top 3 premium cards within the collection.
            </p>

            {/* Mint Information */}
            <div className="space-y-2 mt-4">
              <div className="flex justify-between">
                <span className="text-gray-400">Mint Date:</span>
                <span className="text-gray-300">16 December 2022</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-400">Mint Price:</span>
                <span className="text-gray-300">0.06529 ETH</span>
              </div>
              <div className="flex justify-between">
                <span className="text-gray-400">Supply:</span>
                <span className="text-gray-300">420</span>
              </div>
            </div>

            <div className="flex gap-4 mt-4">
              <a
                href="https://opensea.io/assets/ethereum/0x33fd426905f149f8376e227d0c9d3340aad17af1/47"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 rounded-lg border border-red-900/20 bg-gray-950 px-4 py-2 text-white transition-colors hover:bg-black"
              >
                <img src={openseaIcon} alt="OpenSea" className="w-5 h-5" />
                View on OpenSea
              </a>
              <a
                href="https://seize.io/the-memes/47?focus=live"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 rounded-lg border border-red-900/20 bg-gray-950 px-4 py-2 text-white transition-colors hover:bg-black"
              >
                <img src={seizeIcon} alt="Seize" className="w-5 h-5" />
                View on Seize
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Mobile view: Collapsible Key Metrics */}
      <div className="lg:hidden mb-8">
        <button
          onClick={() => setShowMetrics(!showMetrics)}
          className="w-full text-left px-4 py-2 bg-gray-800 text-white font-semibold rounded-lg"
        >
          Key Metrics {showMetrics ? '▲' : '▼'}
        </button>
        {showMetrics && (
          <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
            {summaryTiles}
          </div>
        )}
      </div>

      {/* Sales Table Section */}
      <div className="mb-8">
        <div className="mb-6 flex items-center justify-between">
          <div>
            <h2 className="text-2xl font-bold text-white">Sales History</h2>
            <p className="text-sm text-gray-400 mt-1">Total Records: {totalRecordsSales}</p>
          </div>
        </div>

        {/* Filter */}
        <div className="mb-4">
          <label htmlFor="saleType" className="mr-2 text-gray-400">Filter by Sale Type:</label>
          <select
            id="saleType"
            value={saleType}
            onChange={(e) => {
              setSaleType(e.target.value);
              setCurrentPageSales(1);
            }}
            className="bg-gray-800 text-gray-300 border border-gray-700 rounded px-3 py-1 focus:outline-none focus:ring-2 focus:ring-gray-600"
          >
            <option value="all">All</option>
            <option value="ask">Ask</option>
            <option value="bid">Bid</option>
          </select>
        </div>

        {/* Sales Table */}
        <div className="w-full overflow-x-auto">
          <table className="min-w-full bg-black border-collapse">
            <thead>
              <tr className="bg-gray-900">
                <th className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('createdat')}>
                  <div className="flex items-center gap-1">
                    Date
                    <ArrowUpDown className="h-4 w-4" />
                    {sortBy === 'createdat' && (sortOrder === 'asc' ? '↑' : '↓')}
                  </div>
                </th>
                <th className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">Artwork</th>
                <th className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">Sale Type</th>
                <th className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('decimal')}>
                  <div className="flex items-center gap-1">
                    Sale Price (ETH)
                    <ArrowUpDown className="h-4 w-4" />
                    {sortBy === 'decimal' && (sortOrder === 'asc' ? '↑' : '↓')}
                  </div>
                </th>
                <th className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider cursor-pointer" onClick={() => handleSort('usd')}>
                  <div className="flex items-center gap-1">
                    Sale Price (USD)
                    <ArrowUpDown className="h-4 w-4" />
                    {sortBy === 'usd' && (sortOrder === 'asc' ? '↑' : '↓')}
                  </div>
                </th>
                <th className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">Seller</th>
                <th className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">Buyer</th>
                <th className="px-6 py-3 border-b border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">Tx Hash</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-800">
              {salesData.map((sale) => (
                <tr key={sale.txhash} className="hover:bg-gray-900 transition-colors duration-150">
                  <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">{formatDate(sale.createdat)}</td>
                  <td className="px-6 py-4 border-r border-gray-800">
                    <img src={sale.image} alt={sale.name} className="w-16 h-16 object-cover rounded" loading="lazy" />
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">{sale.name}</td>
                  <td className="px-6 py-4 text-sm text-gray-300 capitalize border-r border-gray-800">{sale.orderside}</td>
                  <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">{parseFloat(sale.decimal).toFixed(2)} ETH</td>
                  <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">
                    ${parseFloat(sale.usd).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">
                    {sale.from_address.slice(0, 6)}...{sale.from_address.slice(-4)}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">
                    {sale.to_address.slice(0, 6)}...{sale.to_address.slice(-4)}
                  </td>
                  <td className="px-6 py-4">
                    <a href={`https://etherscan.io/tx/${sale.txhash}`} target="_blank" rel="noopener noreferrer">
                      <img src={etherscanIcon} alt="Etherscan" className="w-6 h-6" />
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="mt-4 flex justify-center">
          <ReactPaginate
            previousLabel={'← Previous'}
            nextLabel={'Next →'}
            breakLabel={'...'}
            pageCount={totalPagesSales}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClickSales}
            containerClassName={'flex space-x-2 text-gray-300'}
            pageClassName={'px-3 py-1 border border-gray-700 rounded hover:bg-gray-800 hover:text-white transition-colors duration-150'}
            previousClassName={'px-3 py-1 border border-gray-700 rounded hover:bg-gray-800 hover:text-white transition-colors duration-150'}
            nextClassName={'px-3 py-1 border border-gray-700 rounded hover:bg-gray-800 hover:text-white transition-colors duration-150'}
            breakClassName={'px-3 py-1 border border-gray-700 rounded'}
            activeClassName={'!bg-gray-800 !text-white'}
            disabledClassName={'opacity-50 cursor-not-allowed'}
          />
        </div>
      </div>

      {/* Owners Table Section */}
      <div>
        <div className="mb-6">
          <h2 className="text-2xl font-bold text-white">Owners</h2>
          <p className="text-sm text-gray-400 mt-1">Total Records: {ownersPagination.totalRecords || 'N/A'}</p>
        </div>

        <div className="w-full overflow-x-auto">
          <table className="min-w-full bg-black border-collapse">
            <thead>
              <tr className="bg-gray-900">
                <th className="px-6 py-3 border-b border-r border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">Address</th>
                <th className="px-6 py-3 border-b border-gray-800 text-left text-xs font-semibold text-gray-400 uppercase tracking-wider">Token Count</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-800">
              {ownersData.map((owner) => (
                <tr key={owner.address} className="hover:bg-gray-900 transition-colors duration-150">
                  <td className="px-6 py-4 text-sm text-gray-300 border-r border-gray-800">
                    {owner.address}
                  </td>
                  <td className="px-6 py-4 text-sm text-gray-300">
                    {owner.token_count}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="mt-4 flex justify-center">
          <ReactPaginate
            previousLabel={'← Previous'}
            nextLabel={'Next →'}
            breakLabel={'...'}
            pageCount={ownersPagination.totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClickOwners}
            containerClassName={'flex space-x-2 text-gray-300'}
            pageClassName={'px-3 py-1 border border-gray-700 rounded hover:bg-gray-800 hover:text-white transition-colors duration-150'}
            previousClassName={'px-3 py-1 border border-gray-700 rounded hover:bg-gray-800 hover:text-white transition-colors duration-150'}
            nextClassName={'px-3 py-1 border border-gray-700 rounded hover:bg-gray-800 hover:text-white transition-colors duration-150'}
            breakClassName={'px-3 py-1 border border-gray-700 rounded'}
            activeClassName={'!bg-gray-800 !text-white'}
            disabledClassName={'opacity-50 cursor-not-allowed'}
            forcePage={currentPageOwners - 1}
          />
        </div>
      </div>
    </div>
  );
}

export default MemesSalesComponent;
