// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css'; // Tailwind CSS
import Navbar from './Components/Navbar';
import HomeComponent from './Components/HomeComponent';
import MemesSalesComponent from './Components/MemesSalesComponent';
import XCOPYHodlersComponent from './Components/XCOPYHodlersComponent';
import KnownOriginOwnersComponent from './Components/KnownOriginOwnersComponent';
import KnownOriginPricesComponent from './Components/KnownOriginPricesComponent';
import KnownOriginSalesComponent from './Components/KnownOriginSalesComponent';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<HomeComponent />} />
          <Route path="/xcopy-hodlers" element={<XCOPYHodlersComponent />} />
          <Route path="/exit-strategy" element={<MemesSalesComponent />} />
          <Route path="/known-origin/owners" element={<KnownOriginOwnersComponent />} />
          <Route path="/known-origin/prices" element={<KnownOriginPricesComponent />} />
          <Route path="/known-origin/sales" element={<KnownOriginSalesComponent />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
