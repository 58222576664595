// src/Components/HomeComponent.js
import React from 'react';
import { Link } from 'react-router-dom';
import './HomeComponent.css';

function HomeComponent() {
  return (
    <div className="home-container min-h-screen bg-gray-950 text-white">
      {/* Title Section */}
      <div className="pt-16 pb-12 text-center">
        <h1 className="text-5xl font-bold">
          Welcome to Doomed<span className="text-red-500">X</span> and all things <span className="text-red-500">XCOPY</span>
        </h1>
      </div>

      {/* Navigation Tiles */}
      <div className="max-w-4xl mx-auto px-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <Link 
          to="/xcopy-hodlers"
          className="nav-tile bg-black border border-red-900/20 rounded-lg p-8 text-center hover:bg-gray-900 transition-all duration-300"
        >
          <h2 className="text-3xl font-bold mb-4">
            <span className="text-red-500">XCOPY</span> Hodlers
          </h2>
          <p className="text-gray-400">View all XCOPY NFT holders across collections</p>
        </Link>

        <Link 
          to="/exit-strategy"
          className="nav-tile bg-black border border-red-900/20 rounded-lg p-8 text-center hover:bg-gray-900 transition-all duration-300"
        >
          <h2 className="text-3xl font-bold mb-4">6529 Exit Strategy</h2>
          <p className="text-gray-400">Track sales and ownership of Exit Strategy NFT</p>
        </Link>

        <div className="nav-tile bg-black border border-red-900/20 rounded-lg p-8 text-center hover:bg-gray-900 transition-all duration-300">
          <h2 className="text-3xl font-bold mb-4">
            <span className="text-white">Known</span> <span className="text-red-500">Origin</span>
          </h2>
          <p className="text-gray-400 mb-4">Explore Known Origin NFT data</p>
          <div className="flex flex-col space-y-2">
            <Link 
              to="/known-origin/owners"
              className="ko-link px-4 py-2 bg-gray-800 rounded-md hover:bg-gray-700 transition-colors duration-200"
            >
              Hodlers
            </Link>
            <Link 
              to="/known-origin/prices"
              className="ko-link px-4 py-2 bg-gray-800 rounded-md hover:bg-gray-700 transition-colors duration-200"
            >
              Prices
            </Link>
            <Link 
              to="/known-origin/sales"
              className="ko-link px-4 py-2 bg-gray-800 rounded-md hover:bg-gray-700 transition-colors duration-200"
            >
              Sales
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeComponent;
